@import "~bootstrap/scss/bootstrap";
html {
  scroll-behavior: smooth;
}

.site-logo
  .site-logo-inner.oceanwp-social-menu
  .social-menu-inner
  .site-header.full_screen-header
  .menu-bar-inner
  .after-header-content
  .after-header-content-inner {
  height: 95px;
}

.site-navigation-wrap .dropdown-menu li a .oceanwp-mobile-menu-icon a .mobile-menu-close .after-header-content-inner a {
  line-height: 95px;
}
.site-header-inner {
  justify-content: space-between;
  padding: 11px 0 9px 0;
}

.site-header.has-header-media .overlay-header-media {
  background-color: rgba(0, 0, 0, 0.5);
}

.site-logo .site-logo-inner a img .site-header.center-header .site-navigation-wrap .middle-site-logo a img {
  max-width: 145px;
}

@media (max-width: 480px) {
  .site-logo .site-logo-inner a img .site-header.center-header .site-navigation-wrap .middle-site-logo a img {
    max-width: 81px;
  }
}

.sidr-class-close-text {
  background-color: #8081bb;
  color: #fff;
  padding: 12px 22px;
  font-weight: 400;
}

.footer-bottom {
  background-color: #0c0c0c;
}

.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
}

.fluid-width-video-wrapper iframe .fluid-width-video-wrapper object .fluid-width-video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.__web-inspector-hide-shortcut__
  .__web-inspector-hide-shortcut__
  *
  .__web-inspector-hidebefore-shortcut__::before
  .__web-inspector-hideafter-shortcut__::after {
  visibility: hidden !important;
}

.site-navigation-wrap .dropdown-menu li.btn a span {
  background-color: #8081bb;
  color: #fff;
  padding: 12px 22px;
  font-weight: 400;
}

.site-navigation-wrap .dropdown-menu li.btn a:hover span {
  background-color: #adaeed;
  color: #fff;
}

.elementor-kit-18 {
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
}

.elementor-screen-only,
.screen-reader-text,
.screen-reader-text span,
.ui-helper-hidden-accessible {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  font-size: 14px !important;
  font-weight: 400;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

a {
  color: #333;
  background-color: transparent;
}

.wrap {
  position: relative;
}

.no-header-border #site-header {
  border-bottom: none;
}

.site-header {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  z-index: 100;
}

.site-navigation-wrap {
  float: right;
  position: relative;
  right: -15px;
  margin-left: 50px;
}

.site-navigation-wrap .dropdown-menu li a {
  padding: 0 4px;
}

.article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.sf-menu li.menu-item {
  position: relative;
  white-space: nowrap;
  white-space: normal;
}

.site-navigation-wrap .dropdown-menu li a {
  padding: 0 4px;
}

.col:after,
.clr:after,
.group:after,
dl:after,
.left_float:after,
.right_float:after {
  content: "";
  display: block;
  visibility: hidden;
  clear: both;
  zoom: 1;
  height: 0;
}

.joinchat {
  --red: 37;
  --green: 211;
  --blue: 102;
}

.recentcomments a {
  display: inline !important;
  padding: 0 !important;
  margin: 0 !important;
}

.img.wp-smiley,
img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

/* .navbar-brand:before {
  content: "";
  position: absolute;
  background: mediumpurple;
  width: 10%;
  top: 44px;
  height: -4px;
  left: 7px;
  border-radius: 5px;
  bottom: 0px;
  transform: scaleX(0);
  animation: 1s forwards ani1 linear;
  z-index: -1;
} */


@keyframes ani2 {
  0% {
    transform: scaleX(0);
    height: 5px;
  }
  45% {
    transform: scaleX(1);
    height: 5px;
  }
  55% {
    height: 5px;
  }
  100% {
    transform: scaleX(1);
    height: 40px;
  }
}

@keyframes ani1 {
  0% {
    transform: scaleX(1);
    height: 40px;
  }
  45% {
    height: 5px;
  }
  55% {
    transform: scaleX(1);
    height: 5px;
    opacity: 1;
  }
  100% {
    transform: scaleX(0);
    height: 5px;
    opacity: 0.02;
  }
}

#scroll-top {
  display: none;
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  border-radius: 2px;
  text-align: center;
  z-index: 100;
  box-sizing: content-box;
}
#scroll-top::after {
  display: none;
}
.outer-wrap {
  width: 100% !important;
}
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

.centered-blue {
  background-color: #c9c8e1c7;
  color: #5a5a5a;
}

.elementor-background-overlay{
  width: 100%;
}
